import { Box, Typography } from '@mui/material';
import { IEnrichedUniswapPosition, IPositionToken } from '../../../types/uniswapTokens';
import React, { useEffect, useRef } from 'react';

import { ToggleButtonGroup } from '../../primitives/ToggleButton/ToggleButtonGroup';
import { ToggleButton } from '../../primitives/ToggleButton/ToggleButton';
import { Link } from '../../primitives/Link';
import { MIN_LEVERAGE } from '../LeveragePosition/constants';
import { Slider } from './Slider';
import BigNumber from 'bignumber.js';

export interface IAssetToBorrowProps {
  position: IEnrichedUniswapPosition;
  leverage: number;
  setLeverage: React.Dispatch<React.SetStateAction<number>>;
  tokenToBorrow: IPositionToken | undefined;
  estNetApr?: number;
  setTokenToBorrow: React.Dispatch<React.SetStateAction<IPositionToken | undefined>>
}

export const AssetToBorrow = ({
  position,
  tokenToBorrow,
  setTokenToBorrow,
  estNetApr,
  leverage,
  setLeverage,
}: IAssetToBorrowProps) => {
  const eventTimerIdRef = useRef<NodeJS.Timeout>();

  const onChangeHandler = (_event: React.MouseEvent<HTMLElement>, symbol: string) => {
    if (symbol === position.token0.symbol) {
      setTokenToBorrow(position.token0);
      window.gtag('event', 'leverage-modal-asset-to-borrow', {
        asset: position.token0.symbol,
      });
    }
    if (symbol === position.token1.symbol) {
      setTokenToBorrow(position.token1);
      window.gtag('event', 'leverage-modal-asset-to-borrow', {
        asset: position.token1.symbol,
      });
    }
    setLeverage(MIN_LEVERAGE);
  }

  const availableLeverage = BigNumber(tokenToBorrow?.availableLeverage || MIN_LEVERAGE)
    .decimalPlaces(2, BigNumber.ROUND_DOWN)
    .toNumber();

  useEffect(() => {
    if (leverage > availableLeverage) setLeverage(availableLeverage);
  }, [availableLeverage, leverage]);

  const marks = [
    {
      value: MIN_LEVERAGE,
      label: (
        <Box sx={{ ml: '16px' }}>
          <Typography variant='secondary14'>1x</Typography>
          <Typography variant='secondary12' color="text.secondary">Min</Typography>
        </Box>
      ),
    },
    {
      value: availableLeverage,
      label: (
        <Box  sx={{ ml: '-52px', textAlign: 'right' }}>
          <Typography variant='secondary14'>{availableLeverage}x</Typography>
          <Typography variant='secondary12' color="text.secondary">Available</Typography>
        </Box>
      ),
    },
  ];

  return (
    <Box sx={{ pt: { xs: 0, mb: 5 }, mb: 1 }}>
      <Box sx={{
        display: 'flex',
        justifyContent: 'space-between',
      }}>
        <Typography sx={{ mb: 2 }} variant='main16' color="text.primary">
          Asset to borrow
        </Typography>
        <Link
          href="https://yldr.gitbook.io/yldr/user-guide/how-to-leverage/how-to-choose-an-asset-for-leverage"
          target="_blank"
          sx={{
            color: '#8061E5',
            [':hover']: {
              color: '#a48eed',
            }
          }}
        >
          How to choose?
        </Link>
      </Box>
      <Box sx={{ mb: 5 }}>
        <ToggleButtonGroup
          color="primary"
          exclusive={true}
          value={tokenToBorrow?.symbol}
          onChange={onChangeHandler}
          sx={{ width: '100%', height: '36px', borderRadius: '8px' }}
        >
          <ToggleButton
            value={position?.token0.symbol}
            sx={{ width: '50%', borderRadius: '6px !important' }}
          >
            {position?.token0.symbol}
          </ToggleButton>
          <ToggleButton
            value={position?.token1.symbol}
            sx={{ width: '50%', borderRadius: '6px !important' }}
          >
            {position?.token1.symbol}
          </ToggleButton>
        </ToggleButtonGroup>
      </Box>
      <Typography sx={{ mb: 2 }} variant='main16' color="text.primary">
        Leverage
      </Typography>
      <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'end' }}>
        <Typography variant='main16'>{leverage}x</Typography>
        {!!estNetApr && (
          <Box sx={{ textAlign: 'right' }}>
            <Typography variant='secondary13' color='text.secondary'>
              Estimated Net APR
            </Typography>
            <Typography variant='main16' sx={{
              color: estNetApr > 0 ? '#AFF113' : undefined
            }}>
              {estNetApr.toFixed(2)}%
            </Typography>
          </Box>
        )}
      </Box>
      <Slider
        defaultValue={leverage}
        value={leverage}
        marks={marks}
        step={0.01}
        min={1}
        max={availableLeverage}
        onChange={(_event, value) => {
          const leverageValue = value as number;
          const newLeverageValue = leverageValue >= availableLeverage
            ? availableLeverage
            : leverageValue;
          setLeverage(newLeverageValue);
          eventTimerIdRef.current && clearTimeout(eventTimerIdRef.current);
          eventTimerIdRef.current = setTimeout(() => {
            window.gtag('event', 'leverage_modal_change_leverage', {
              value: newLeverageValue,
            });
          }, 2000);
        }}
      />
    </Box>
  );
};
