import { useMemo } from 'react';

export const useLeveragePositionValues = ({
  liquidityTokenA = 0,
  liquidityTokenB = 0,
  marketReferenceCurrencyValue = 0,
  priceInMarketReferenceCurrencyA = 0,
  priceInMarketReferenceCurrencyB = 0,
  leverage = 1,
}: {
  liquidityTokenA?: number;
  liquidityTokenB?: number;
  marketReferenceCurrencyValue?: number;
  priceInMarketReferenceCurrencyA?: string | number;
  priceInMarketReferenceCurrencyB?: string | number;
  leverage?: number;
}) => {
  return useMemo(() => {
    const totalAmountUsd = Math.round(marketReferenceCurrencyValue * leverage * 10000) / 10000;
    const amountToken0 = Math.round(liquidityTokenA * leverage * 10000) / 10000;
    const amountToken1 = Math.round(liquidityTokenB * leverage * 10000) / 10000;
    const amountUsdToken0 = Math.round(amountToken0 * Number(priceInMarketReferenceCurrencyA) * 10000) / 10000;
    const amountUsdToken1 = Math.round(amountToken1 * Number(priceInMarketReferenceCurrencyB) * 10000) / 10000;
    return {
      totalAmountUsd,
      amountToken0,
      amountToken1,
      amountUsdToken0,
      amountUsdToken1,
    }
  }, [
    liquidityTokenA,
    liquidityTokenB,
    marketReferenceCurrencyValue,
    priceInMarketReferenceCurrencyA,
    priceInMarketReferenceCurrencyB,
    leverage,
  ]);
}
