import { useQuery } from '@tanstack/react-query';
import { FAST_POLLING_INTERVAL, QueryKeys } from '../../ui-config/queries';
import { useProtocolDataContext } from '../useProtocolDataContext';
import { IMarketDataResponse } from './useMarketsQuery';
import { FeeAmount } from '@uniswap/v3-sdk';

export interface IEstimatedUsdFeesPerDayRequest {
  endpointURL: string;
  token0?: string;
  token1?: string;
  fee?: number;
  marketId?: number;
}

export interface IEstimatedUsdFeesPerDayResponse {
  chain_id: number;
  token_0: string;
  token_1: string;
  fee: number;
  address: string;
  tvl_usd: string;
  estimated_usd_fees_per_day: string;
  liquidity: number;
  market: IMarketDataResponse;
}

export const getEstimatedUsdFeesPerDay = async ({
  endpointURL,
  marketId,
  token0,
  token1,
  fee,
}: IEstimatedUsdFeesPerDayRequest) => {
  try {
    const url = `${endpointURL}/concentrated_liquidity/pool?token_0=${token0}&token_1=${token1}&fee=${fee}&market_id=${marketId}`;
    const result = await fetch(url);
    const json: IEstimatedUsdFeesPerDayResponse = await result.json();
    return json;
  } catch (e) {
    return null;
  }
};

const INITIAL_DATA = {} as IEstimatedUsdFeesPerDayResponse ;

export const useEstimatedUsdFeesPerDayQuery = ({
  marketId,
  token0,
  token1,
  feeAmount = 0,
  enabled = true,
}: Partial<Omit<IEstimatedUsdFeesPerDayRequest, 'endpointURL' | 'chainId' | 'fee'>> & { enabled?: boolean; feeAmount?: FeeAmount }) => {
  const { currentNetworkConfig } = useProtocolDataContext();
  const endpointURL = currentNetworkConfig.yldrYpiUrl;

  const { data, ...rest } = useQuery({
    queryKey: [QueryKeys.ESTIMATED_USD_FEES_PER_DAY, marketId, token0, token1, feeAmount],
    queryFn: () => getEstimatedUsdFeesPerDay({
      endpointURL,
      marketId,
      token0,
      token1,
      fee: feeAmount,
    }),
    enabled: enabled && Boolean(marketId && token0 && token1),
    refetchInterval: FAST_POLLING_INTERVAL,
  });

  return {
    data: data || INITIAL_DATA,
    ...rest,
  }
}
